<template></template>

<script>
import global from "@/common/global"
export default {
    data() {
        return {
            openid: "",
        }
    },
    mounted(){
        this.getCode()
    },
    methods: {
        //查询用户
        queryInfo: function (openidvar) {
			//查询机构信息
			this.$ajax({
				url: "/gateway/ed/wx/wxEdExpertInfo/wxlogin",
			    method: "post",
			    params: {
			        openid: openidvar,
                    appId: global.appid,
                    appSecret: global.secret
			    },
			})
			.then((res)=>{
                // 判断设备
                let isPc = true
                if(/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
                    isPc = false
                }
				//有用户和机构
				if(res.status=="200"&&res.data.org){
					//org
					this.$ls.set("edCurrentOrg",res.data.org)
					//扩展信息
					if(res.data.external){
						this.$ls.set("edCurrentOrgExternal",res.data.external)
					}
					//增值服务
					if(res.data.openRecord){
						this.$ls.set("edOpenRecord",res.data.openRecord)
					}
					//token
                    this.$ls.set("token",res.data.token)
                    //当前用户信息
                    this.$ls.set("edCurrentUser",res.data.edCurrentUser)
                    //判断是否有昵称
                    let nameValue = res.data.edCurrentUser.userExternal.name;
                    if(nameValue==null||nameValue==''||nameValue==undefined){
                     // 将设备类型存入缓存
                      this.$ls.set('isPc',isPc)
                      //跳转获取昵称页面
                      this.$router.push({name: "nickname"})
                    }else if(isPc){
                        window.location.href= global.pcUrl
                    }else {
                        //跳转home
					    this.$router.push({ name: "home" })
                    }


				}else{
                    if(res.data.invalidOrg){
                        //失效的操作员机构数据
                        this.$ls.set("invalidOrg",res.data.invalidOrg)
                    }else{
                        this.$ls.remove('invalidOrg');
                    }
                    if(isPc){
                        window.location.href= global.pcUrl + "register"
                    }else{
                        //跳转欢迎页
					    this.$router.push({ name: "index" })
                    }
				}
			})
			.catch((err)=>{
				this.$toast("登录出错,请稍候重试！")
				return
			})

        },
        //获取code
        getCode: function () {
			let code = this.getUrlParam("code")
			if(code){
				let accessUrl =
                    "/wxauth/sns/oauth2/access_token?appid="+global.appid+"&secret="+global.secret+"&code=" +
                    code +
                    "&grant_type=authorization_code"
                //发起请求
                this.$ajax
                    .get(accessUrl)
                    .then((res) => {
                        //获取成功
                        if (res.status == "200") {
                            //获取openid 保存session
                            this.$ls.set("openid", res.data.openid);
                            this.getunionid();
                        }else{
							this.$toast("获取用户微信信息出错！")
						}
						//跳转首页
                    }).catch((err) => {
                        this.$toast.fail(err)
                    });
			}else{
				//发起请求获取code
                let localUrl = window.location.href //回调url-本页
                //店铺公众号
                window.location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+global.appid+"&redirect_uri=" +
                    encodeURIComponent(localUrl) +
                    "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
			}
          //
        },
        //解析url
        getUrlParam: function (name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
            var r = window.location.search.substr(1).match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //获取微信unionid
        getunionid(){
            //获取openid
            if(this.$ls.get("openid")){
                //获取access_token
                let tokenUrl = "/wxauth/cgi-bin/token?grant_type=client_credential&appid="+global.appid+"&secret="+global.secret;
                this.$ajax
                .get(tokenUrl)
                .then((res)=>{
                    if(res.status=="200"){
                    let token = res.data.access_token;
                    let nicknameUrl = "/wxauth/cgi-bin/user/info?access_token="+token+"&openid="+this.$ls.get("openid")+"&lang=zh_CN";
                   //获取昵称
                    this.$ajax
                        .get(nicknameUrl)
                        .then((res)=>{
                        if(res.status=="200"){
                            if(res.data.subscribe=="1"){
                            //写入数据库
                            let data = {};
                            data.unionid = res.data.unionid;
                            data.openid = this.$ls.get("openid");
                            data.appId = global.appid;
                            data.appSecret = global.secret;
                            data.description ='专家记';

                            this.$ajax({
                                method: "post",
                                url: "/gateway/sys/tbsWxUserMap/update",
                                headers:{
                                'X-Access-Token':this.$ls.get("token")
                                },
                                params: {
                                    openid:this.$ls.get("openid"),
                                },
                                data: data
                            }).then((res)=>{
                                if(res.status=="200"&&res.data.success==true){
                                    //先清除缓存
                                    this.$ls.remove("edCurrentOrg");
                                    this.$ls.remove("edCurrentOrgExternal");
                                    this.$ls.remove("edOpenRecord");
                                    this.$ls.remove("edCurrentUser");
                                    this.$ls.remove("token");
                                    this.queryInfo(this.$ls.get("openid"))
                                }else{
                                    this.$toast("出现异常,请稍候重试");
                                }
                                });
                            }else{
                                this.$toast.fail("请先关注公众号，再进行注册使用！");
                                setTimeout(() => {
                                    window.location.href=global.OfficialAccountUrl
                                }, 1200);
                                return
                                }
                        }else{
                            this.$toast("出现异常,请稍候重试");
                        }
                        });
                    }else{
                    this.$toast("出现异常,请稍候重试");
                    }
                });
            }else{
                this.$toast("请先注册");
                this.$router.push({ name: "index" });
            }
        }
    },
}
</script>

<style>
</style>

